import * as React from 'react';

export function ErrorIcon(): JSX.Element {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zm1.212-16.8a1.277 1.277 0 00-1.32-1.2c-.696.023-1.248.624-1.2 1.296l.311 6.695c.025.48.408.888.913.912a.959.959 0 00.984-.912l.312-6.671V7.2zM10.5 17.663c0 .793.648 1.44 1.44 1.44.792 0 1.44-.647 1.44-1.44 0-.792-.648-1.44-1.44-1.44-.792 0-1.44.648-1.44 1.44z"
                fill="#F62F36"
            />
        </svg>
    );
}
