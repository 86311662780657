import React from 'react';
import { AlertType } from './constants/alertTypes';
import { ErrorIcon } from '../../icons/ErrorIcon';
import { WarningIcon } from '../../icons/WarningIcon';
import { InformationIcon } from '../../icons/InformationIcon';
import style from './styles/Alert.module.scss';

type AlertProps = {
    type: AlertType;
    message: string | JSX.Element;
};

export function Alert({ type, message }: AlertProps): JSX.Element {
    function pickIcon(): JSX.Element {
        switch (type) {
            case AlertType.Error:
                return <ErrorIcon/>;
            case AlertType.Warning:
                return <WarningIcon/>;
        }
        return <InformationIcon/>;
    }

    function pickColor(): string {
        switch (type) {
            case AlertType.Error:
                return style.error;
            case AlertType.Warning:
                return style.warning;
        }
        return '';
    }

    return (
        <div role="alert" className={style.container}>
            <div>{pickIcon()}</div>
            <div className={`${style.text} ${pickColor()}`}>{message}</div>
        </div>
    );
}
