import * as React from 'react';

export function InformationIcon(): JSX.Element {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zm.9-6.9c.3 0 .75-.15.75-.15.15-.15.3 0 .3.15.15.15.15.3 0 .45 0 0-.9.75-2.25.75h-.75c-.6-.15-1.05-.45-1.35-1.05-.3-.6-.3-1.5.15-2.25l.75-1.5c.3-.75.15-1.05 0-1.2h-.3c-.3 0-.75.15-.75.15-.15 0-.3 0-.45-.15 0-.15 0-.3.15-.45 0 0 .9-.75 2.25-.75h.75c.6.15 1.05.6 1.35 1.05.3.75.15 1.5-.15 2.4l-.75 1.5c-.3.75-.15.9 0 1.05h.3zm-1.8-9.3c0-1.05.9-1.8 1.8-1.8 1.05 0 1.95.75 1.8 1.8 0 1.05-.75 1.8-1.8 1.8s-1.8-.75-1.8-1.8z"
                fill="#1887E8"
            />
        </svg>
    );
}
