import React, { type InputHTMLAttributes } from 'react';
import { Input, InputGroup, InputGroupIcon, HideIcon, ShowIcon } from '@gr/ui-neo';
import styles from './styles/InputPassword.module.scss';

interface InputPasswordProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
    isInvalid?: boolean;
}

export const InputPassword = ({ isInvalid, value, onChange, ...otherProps }: InputPasswordProps) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleShowPassword = React.useCallback(() => {
        setShowPassword(!showPassword);
    }, [showPassword]);

    return (
        <InputGroup
            addonPlacement="end"
            addon={
                <InputGroupIcon onClick={handleShowPassword} className={styles.pointer}>
                    {showPassword ?
                        <HideIcon color="lightSlathe" colorHovered="slathe"/> :
                        <ShowIcon color="lightSlathe" colorHovered="slathe"/>}
                </InputGroupIcon>
            }
        >
            <Input isInvalid={isInvalid} value={value} onChange={onChange} type={showPassword ? 'text' : 'password'} {...otherProps}/>
        </InputGroup>
    );
};
