import React, { type ReactNode, type SyntheticEvent, useEffect, useState, useRef } from 'react';
import { getSession } from '../../utils/getSession';
import { Box, Button, Center, Flex, FormField, Heading, Input, Text, Link as NeoLink } from '@gr/ui-neo';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { type GetServerSidePropsContext, type GetServerSidePropsResult } from 'next';
import { Layout } from '../../types/layouts';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert } from '../../components/Alert/Alert';
import { AlertType } from '../../components/Alert/constants/alertTypes';
import style from '../../styles/login/Login.module.scss';
import { InputPassword } from '../../ui/InputPassword/InputPassword';
import styles from '../../components/account/PersonalDetails/styles/PersonalDetails.module.scss';
import useSession from '../../hooks/useSession';
import { CUSTOMER_SUPPORT_URL } from '../../constants/urls';
import ReCAPTCHA from 'react-google-recaptcha';

type SignInProps = {
    ip?: string;
    recaptchaWebsiteId?: string;
};

SignIn.layout = Layout.NOTAUTHORIZED;

export default function SignIn({ ip, recaptchaWebsiteId }: SignInProps): JSX.Element {
    const { signIn } = useSession();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [captcha, setCaptcha] = useState(null);
    const [showCaptcha, setShowCaptcha] = useState(false);

    const [isLoggingIn, setIsLoggingIn] = React.useState(false);
    const { formatMessage } = useIntl();
    const [notificationData, setNotificationData] = useState({
        display: false,
        type: AlertType.Info,
        message: <div/>,
    });
    const router = useRouter();
    const captchaRef = useRef<ReCAPTCHA>(null);

    const handleCaptchaChange = (value: string) => {
        setCaptcha(value);
    };

    function handleSubmitClick(event: SyntheticEvent) {
        event.preventDefault();

        if (showCaptcha && captcha === null) { return; }

        setIsLoggingIn(true);
        (async () => loginUser())();
    }

    useEffect(() => {
    // TODO: we need to change it for better handling errors
        if (router.query.error === 'invalid') {
            setNotificationData({
                display: true,
                type: AlertType.Error,
                message: <div>{formatMessage({ id: 'Notifications.InvalidLoginOrPassword' })}</div>,
            });
        } else if (router.query.rp === 'success') {
            setNotificationData({
                display: true,
                type: AlertType.Info,
                message: <div>{formatMessage({ id: 'Notifications.PasswordChangedLine1' })}</div>,
            });
        } else if (router.query.np === 'yes') {
            // notification for IAP users redirected on failed login
            setNotificationData({
                display: true,
                type: AlertType.Info,
                message: <div>{formatMessage({ id: 'Notifications.NewPanelInfo' })}</div>,
            });
        }
    }, [router, formatMessage]);

    function supportLink(): JSX.Element {
        return (
            <Button variant="ghost" size="extra-small" marginX="0px" className={styles.supportLink}>
                <Link href={CUSTOMER_SUPPORT_URL} style={{ all: 'unset' }}>
                    <FormattedMessage id="Notifications.SupportLinkText"/>
                </Link>
            </Button>
        );
    }

    async function loginUser() {
        try {
            const response = await fetch('/user/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password, ip, captcha }),
            });

            const data = await response.json();

            if (response.status === 200) {
                const { user } = data;
                signIn({ ...user, isSupport: 'no' });
                window.location.replace('/');
            } else {
                setIsLoggingIn(false);
                let message;

                switch (data.error) {
                    case 'invalid_captcha': {
                        message = formatMessage({ id: 'Notifications.InvalidLoginOrPassword' });
                        setShowCaptcha(true);
                        break;
                    }
                    case 'inactive': {
                        message = formatMessage({ id: 'Notifications.AccountInactive' }, { contact_support_link: supportLink() });
                        break;
                    }
                    case 'unconfirmed': {
                        message = formatMessage({ id: 'Notifications.AccountUnconfirmed' });
                        break;
                    }
                    case 'locked': {
                        message = formatMessage({ id: 'Notifications.AccountLocked' });
                        break;
                    }
                    case 'last_attempt': {
                        message = formatMessage({ id: 'Notifications.AccountLastAttempt' });
                        break;
                    }
                    default: {
                        message = formatMessage({ id: 'Notifications.InvalidLoginOrPassword' });
                        break;
                    }
                }

                setNotificationData({
                    display: true,
                    type: AlertType.Error,
                    message: <div>{message}</div>,
                });
            }
        } catch (error) {
            setNotificationData({
                display: true,
                type: AlertType.Error,
                message: <div>Login failed</div>,
            });
        } finally {
            setCaptcha(null);
            captchaRef.current?.reset();
        }
    }

    function handleEmailChange(event: SyntheticEvent): void {
        const { value } = event.target as HTMLInputElement;
        setEmail(value);
    }

    function handlePasswordChange(event: SyntheticEvent): void {
        const { value } = event.target as HTMLInputElement;
        setPassword(value);
    }

    function loginWithGetResponseButton(): JSX.Element {
        return (
            <Link href="/auth/getresponse">
                <Button
                    type="button"
                    color="secondary"
                    variant="secondary"
                    style={{ paddingLeft: '25px', paddingRight: '25px' }}
                    data-testid="login_button"
                    isDisabled={isLoggingIn}
                >
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '245px',
                        gap: '15px',
                    }}
                    >
                        <img src="/gr-logo.svg" alt="getresponse" width="25px" height="25px"/>
                        <Text className={style.formPasswordLink}>
                            <FormattedMessage id="LoginForm.LoginWithGetresponse"/>
                        </Text>
                    </div>
                </Button>
            </Link>
        );
    }

    function loginWithGoogleButton(): JSX.Element {
        return (
            <Link href="/auth/google">
                <Button
                    type="button"
                    color="secondary"
                    variant="secondary"
                    style={{ paddingLeft: '25px', paddingRight: '25px', marginTop: '30px', width: '100%' }}
                    data-testid="login_button"
                    isDisabled={isLoggingIn}
                >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '245px', gap: '15px' }}>
                        <img src="/google.png" alt="google" width="25px" height="25px"/>
                        <Text className={style.formPasswordLink}>
                            <FormattedMessage id="LoginForm.LoginWithGoogle"/>
                        </Text>
                    </div>
                </Button>
            </Link>
        );
    }

    function loginWithAppleButton(): JSX.Element {
        return (
            <Link href="/auth/apple">
                <Button
                    type="button"
                    color="secondary"
                    variant="secondary"
                    style={{ paddingLeft: '25px', paddingRight: '25px', marginTop: '30px', width: '100%' }}
                    isDisabled={isLoggingIn}
                >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '245px', gap: '15px' }}>
                        <img src="/apple.png" alt="apple" width="19px" height="25px"/>
                        <Text className={style.formPasswordLink}>
                            <FormattedMessage id="LoginForm.LoginWithApple"/>
                        </Text>
                    </div>
                </Button>
            </Link>
        );
    }

    const renderRecaptcha = () => {
        if (recaptchaWebsiteId && showCaptcha) {
            return (
                <ReCAPTCHA
                    ref={captchaRef}
                    sitekey={recaptchaWebsiteId}
                    onChange={handleCaptchaChange}
                    enterprise="true"
                />
            );
        }

        return null;
    };

    return (
        <Center>
            <Flex
                tag="form"
                method="post"
                action=""
                boxShadow="card"
                alignItems="center"
                flexDirection="column"
                minWidth="300px"
                maxWidth="700px"
                minHeight="615px"
                paddingX="32px"
                paddingBottom="50px"
                borderRadius="12px"
                width="100%"
                onSubmit={handleSubmitClick}
            >
                <input name="ip" type="hidden" defaultValue={ip}/>
                <Heading level={2} align="center" marginTop="50px" marginBottom="35px">
                    <FormattedMessage id="LoginForm.Title"/>
                </Heading>
                {notificationData.display ?
                    <Alert type={notificationData.type} message={notificationData.message}/> : null}
                <FormField
                    id="login"
                    label={formatMessage({ id: 'LoginForm.Login' })}
                    marginBottom="23px"
                    width="100%"
                    maxWidth="500px"
                >
                    <Input
                        name="email"
                        type="text"
                        value={email}
                        onChange={handleEmailChange}
                        aria-label={formatMessage({ id: 'LoginForm.Login' })}
                        data-testid="email"
                    />
                </FormField>

                <FormField
                    id="password"
                    label={formatMessage({ id: 'LoginForm.Password' })}
                    marginBottom="23px"
                    width="100%"
                    maxWidth="500px"
                    position="relative"
                >
                    <Button
                        variant="ghost"
                        size="small"
                        className={style.formPasswordLinkButton}
                        position="absolute"
                        right="0px"
                        marginX="0px"
                    >
                        <Link href="/forgot-password" className={style.formPasswordLink}>
                            <FormattedMessage id="LoginForm.ForgotPassword"/>
                        </Link>
                    </Button>
                    <InputPassword
                        name="password"
                        value={password}
                        onChange={handlePasswordChange}
                        aria-label={formatMessage({ id: 'LoginForm.Password' })}
                        data-testid="password"
                    />
                </FormField>

                {renderRecaptcha()}

                <Box marginTop="21px" maxWidth="300px" width="100%">
                    <Button type="submit"
                        width="100%"
                        onClick={handleSubmitClick}
                        isLoading={isLoggingIn}
                        isDisabled={isLoggingIn || (showCaptcha && captcha === null)}
                    >
                        <FormattedMessage id="LoginForm.SignIn"/>
                    </Button>
                    <Flex alignItems="center" justifyContent="center" marginY="32px">
                        <div className={style.formDividerSeparator}/>
                        <Text variant="body-large-bold" color="black" marginX="8px" textTransform="uppercase">
                            <FormattedMessage id="LoginForm.Separator"/>
                        </Text>
                        <div className={style.formDividerSeparator}/>
                    </Flex>

                    {loginWithGetResponseButton()}
                    {loginWithGoogleButton()}
                    {loginWithAppleButton()}
                </Box>
                <Box marginTop="29px">
                    <Text variant="body-small" tag="span" color="slathe">
                        <FormattedMessage id="LoginForm.NoAccount"/>
                    </Text>{' '}
                    <Button
                        tag="a"
                        variant="ghost"
                        href="https://www.getresponse.com/affiliate-programs"
                        target="_blank"
                        size="extra-small"
                        marginX="0px"
                        className={style.formSignUpLink}
                    >
                        <FormattedMessage id="LoginForm.SignUp"/>
                    </Button>
                </Box>

                <Box marginTop="20px" maxWidth="500px">
                    <Text variant="body-small" color="slathe" align="center">
                        <FormattedMessage
                            id="LoginForm.RulesLinks"
                            values={{
                                'tos-link': (...chunks: ReactNode[]) => (
                                    <NeoLink href={formatMessage({ id: 'Links.Tos' })} target="_blank">
                                        {chunks}
                                    </NeoLink>
                                ),
                                'privacy-policy-link': (...chunks: ReactNode[]) => (
                                    <NeoLink href={formatMessage({ id: 'Links.PrivacyPolicy' })} target="_blank">
                                        {chunks}
                                    </NeoLink>
                                ),
                            }}
                        />
                    </Text>
                </Box>
            </Flex>
        </Center>
    );
}

export async function getServerSideProps(context: GetServerSidePropsContext): Promise<GetServerSidePropsResult<SignInProps>> {
    const { req } = await context;
    const session = getSession(req);

    if (session !== null) {
        return {
            redirect: {
                destination: '/',
                permanent: false,
            },
        };
    }

    const real_ip = req.headers['x-real-ip'] as string;
    const forwarded = req.headers['x-forwarded-for'] as string;
    const ip = real_ip || (forwarded ? forwarded.split(/, /)[0] : req.socket.remoteAddress);
    const recaptchaWebsiteId = process.env.RECAPTCHA_WEBSITE_ID;

    return {
        props: {
            ip,
            recaptchaWebsiteId,
        },
    };
}
