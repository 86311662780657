import * as React from 'react';

export function WarningIcon(): JSX.Element {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.112 18.864l-9.24-15.912c-.84-1.44-2.928-1.44-3.744 0L.888 18.864c-.84 1.44.215 3.265 1.872 3.265h18.456c1.68-.001 2.736-1.801 1.896-3.265zm-11.16-12.6a1.277 1.277 0 011.32 1.2v.12l-.312 6.672a.959.959 0 01-.984.912.963.963 0 01-.913-.912l-.312-6.696c-.047-.672.505-1.272 1.201-1.295zM12 19.368c-.792 0-1.44-.648-1.44-1.44 0-.792.648-1.44 1.44-1.44.792 0 1.44.648 1.44 1.44 0 .792-.648 1.44-1.44 1.44z"
                fill="#F8A14A"
            />
        </svg>
    );
}
